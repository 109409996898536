// 
// Buttons
// 
.btn:hover,
.btn:focus,
.btn:active {
    box-shadow: none !important;
}

button {
    cursor: pointer;
}

.btn {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 14px 45px;
    color: $dark;
    border: 1px solid transparent;
    outline: 0px;
    box-shadow: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 0px;
    text-align: center;
    &:active:not(:disabled):not(.disabled),
    &:hover:not(:disabled):not(.disabled),
    &:focus:not(:disabled):not(.disabled),
    &:active:not(:disabled):not(.disabled) {
        background-color: transparent;
        color: $primary;
        
    }
}

// sizes
.btn-lg{
    padding: 20px 55px;
    font-size: 14px;
}

.btn-primary{
    border-color: $primary;
    color: $primary;
    background-color: transparent;
    &:active:not(:disabled):not(.disabled),
    &:hover:not(:disabled):not(.disabled),
    &:focus:not(:disabled):not(.disabled),
    &:active:not(:disabled):not(.disabled) {
        background-color: $primary;
        color: $white;
        border-color: $primary;
    }
}

.btn-secoundary{
    border-color: $gray-300;
    color: $white;
    background-color: transparent;
    &:active:not(:disabled):not(.disabled),
    &:hover:not(:disabled):not(.disabled),
    &:focus:not(:disabled):not(.disabled),
    &:active:not(:disabled):not(.disabled) {
        background-color: $primary;
        color: $white;
        border-color: $primary;
    }
}


.btn-white{
    border-color: $white;
    color: $white;
    background-color: transparent;
    &:active:not(:disabled):not(.disabled),
    &:hover:not(:disabled):not(.disabled),
    &:focus:not(:disabled):not(.disabled),
    &:active:not(:disabled):not(.disabled) {
        background-color: $dark;
        color: $white;
        border-color: $dark;
    }
}

// 
// Link line
// 
.more {
    display: inline-block;
    background-color: transparent;
    outline: none;
    border:0px;
    padding: 6px 0;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    color: $dark;
    position: relative;
    &:before, &:after{
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $gray-300;
        height: 1px;
    }
    &:before{
        width: 100%;
    }

    &:after{
        height: 3px;
        width: 30%;bottom: -2px;
        transition: all 0.3s;
        background-color: $primary;
    }
}

.more-white{
    color: $white;
    &:before{
        background-color: $white;
    }
    &:after{
        background-color: $dark;
    }
}

.more:hover{
    &:after{
        width: 100%;
    }
}

.more-white:hover{
    color: $white;
}
