//
// Bredcrumbs
//
.breadcrumb {
    background-color: $white;
    border-radius: 0px;
    margin: 0;
    padding: 30px 0;
    align-items: center;
    position: relative;
    &:before{
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 100%;
        width: 100vw;
        background-color: $white;
    }
    
    &-item,
    &-item a {
        font-family: 'Asap', sans-serif;
        font-size: 14px;
        color: $text-color;
        transition: all 0.3s;
    }
    &-item a:hover {
        color: $primary;
    }
    &-item+&-item::before {
        padding-right: 8px;
        padding-left: 8px;
        content: "-";
    }
    &-item.active {
        color: $primary;
    }
}

@include media-breakpoint-down(md) {
    .breadcrumb {
        display: none;
    }
}