// 
// Sidebar
// 

.sidebar {
    padding: 40px 20px;
    background-color: $gray-100;
    .widget + .widget{
        margin-top: 45px;
    }
    .widget-title{
        font-weight: 400;
        margin-bottom: 20px;
    }
}

// 
// widget menu
// 
ul.widget-menu{
    margin: 0;
    padding: 0;
    > li{
        margin-bottom: 20px;
    }
    > li:last-child{
        margin-bottom: 0;
    }
    a{
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        color: $dark;
    }
    a.active, a:hover{
        color: $primary;
    }
}


// 
// Recent posts
// 
ul.recent-posts {
    li {
        padding: 15px 0;
        border-bottom: 1px solid $gray-200;
    }
}

.recent-item {
    display: flex;
    .recent-image {
        width: 80px;
        flex: 0 0 80px;
    }
    .recent-content {
        padding: 5px 15px;
        padding-right: 0;
        padding-top: 3px;
        h6,
        p {
            transition: color 0.3s;
        }
        h6 {
            font-size: 12px;
            font-weight: 300;
            color: $dark;
            margin-bottom: 5px;
        }
        p {
            font-size: 13px;
            margin: 0;
            color: $dark;
        }
    }
}

.recent-item:hover {
    h6,
    p {
        color: $primary;
    }
}


@include media-breakpoint-down(lg) {
    .recent-item .recent-image{
        width: 60px;
        flex: 0 0 60px;
    }
}


@include media-breakpoint-down(md) {
    .sidebar{
        margin-bottom: 25px;
    }
    ul.widget-menu > li{
        margin-bottom: 10px;
    }
}
