// 
// page: about us
// 

.about-hero{
    position: relative;
    padding-left: 50%;
    margin-bottom: 100px;
    padding-top: 80px;
    .about-hero-image{
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 50px;
        &:after{
            content: "";
            position: absolute;
            bottom: -17px;
            right: 0;
            height: 17px;
            width: 70%;
            background-color: $primary;
        }
    }

    .about-hero-text{
        position: relative;
        z-index: 2;
        background-color: $white;
        padding: 10% 15%;
        h5{
            font-weight: 400;
            margin-bottom: 40px;
            
        }
        p{
            margin-bottom: 30px;
        }
    }
}

// history item
.history-item{
    position: relative;
    padding-top: 70px;
    .item-image{
        position: relative;
    }
    .item-image-year{
        position: absolute;
        z-index: 69;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $primary;
        color: $white;
        font-size: 38px;
        font-weight: 700;
        padding: 8px 45px;
        
    }
    .item-content{
        padding: 20px;
        padding-top: 45px;
        h5{
            font-weight: 400;
            margin-bottom: 40px;
        }
        p{
            margin-bottom: 25px;
        }
        p:last-of-type{
            margin-bottom: 0;
        }
    }
}

.history-item-static{
    padding-bottom: 100px;
    .item-content{
        padding: 20px 10%;
        padding-top: 45px;
    }
}


ul.history-timeline{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 80px 0;
    margin-bottom: 100px;
    &:after{
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 1px;
        background-color: $gray-200;
    }
    > li{
        flex: 0 0 50%;
    }
    .history-item{
        &:before{
            content: "";
            position: absolute;
            height: 1px;
            background-color: $gray-200;
            width: 110px;
            top: 140px;
        }
    }
    > li:nth-child(odd) .history-item{
        padding-right: 130px;
        &:before{
            right: 0;
        }
        
    }
    > li:nth-child(even) .history-item{
        padding-left: 130px;
        &:before{
            left: 0;
        }
    }

    > li:nth-child(even){
        padding-top: 240px;
    }
}


@include media-breakpoint-down(xl) {
    .history-item {
        .item-image-year{
            font-size: 28px;
        }
    }
}


@include media-breakpoint-down(md) {
    ul.history-timeline{
        margin-bottom: 20px;
        padding: 0;
        &:after{
            content: none;
        }
        display: block;
        > li:nth-child(even) {
            padding-top: 0px;
        }
        > li:nth-child(odd) .history-item {
            padding-right: 0px;
        }
        > li:nth-child(even) .history-item {
            padding-left: 0px;
        }
        .history-item:before{
            content: none;
        }
    }
    .history-item-static {
        padding-bottom: 0px;
        .item-content{
            padding: 20px 0;
        }
    }

    .about-hero{
        padding-left: 20%;
    }
    
}


@include media-breakpoint-down(sm) {
    .about-hero{
        padding: 0;
        margin: 0;
        .about-hero-text{
            padding: 0;
        }
        .about-hero-image{
            display: none;
        }
    }
}