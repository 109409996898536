//
// FORM CONTROL
//
.form-control {
    border-radius: 0px;
    font-size: 14px;
    padding: 10px 20px;
    height: 45px;
    font-weight: 300;
    color: $text-color;
    background-color: transparent;
    border: 1px solid $gray-300;
    color: $text-color;
}

.form-control:focus {
    background-color: transparent;
    box-shadow: none;
    border-color: $primary;
}

textarea.form-control {
    height: auto;
    line-height: 1.3;
}

label{
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 5px;
    padding-left: 20px;
    color: rgba( $dark, 0.6 );
}

//
// FORM GROUP
//
.form-group {
    position: relative;
    margin-bottom: 20px;
}


//
// PLACEHOLDERS
//
input ::-webkit-input-placeholder {
    color: $text-color;
}

input ::-moz-placeholder {
    color: $text-color;
}

input :-ms-input-placeholder {
    color: $text-color;
}

input :-moz-placeholder {
    color: $text-color;
}


// 
// Nice select
// 
.nice-select {
    font-size: 15px;
    color: $text-color;
    width: 100%;
    z-index: 88;
    border-color: $gray-200;
    border-radius: 0px;
    position: relative;
    z-index: 89;
    height: 45px;
    line-height: 45px;
    float: none;
    display: block;

    &:before{
        content: "";
        position: absolute;
        right: 0px;
        top: 0px;
        bottom: 0px;
        width: 28px;
        background-color: $gray-400;
    }
    &:after{
        border:0px;
        content: "\e920";
        font-family: 'icomoon' !important;
        font-size: 8px;
        color: $dark;
        transform: none;
        width: auto;
        height: auto;
        right: 9px;
        line-height: 1;
    }
    .list {
        font-size: 13px;
        left: 0 !important;
        right: 0 !important;
        z-index: 999999;
        border-radius: 0px;
    }
    .option{
        min-height: 50px;
        line-height: 50px;
        color: $primary;
    }
}

.nice-select:active, .nice-select.open, .nice-select:focus {
    border-color: $primary;
    &:after{
        content: "\e923";
        transform: none;
    }
}

.nice-select.select-small{
    display: inline-block;
    border:0px;
    font-size: 15px;
    font-weight: 400;
    text-align: right!important;
    height: 40px;
    line-height: 40px;
    width: auto;
    min-width: 220px;
    &:before{
        content: none;
    }
}

.nice-select.select-up{
    .list {
        top: auto;
        bottom: 100%;
    }
}

.select-fixed{
    .list{
        max-height: 250px;
        overflow-y: auto;
    }
}

//
// VALIDATION
//
.parslay-danger {
    display: none;
}

.form-control.form-control-danger {
    border-color: $danger;
}

.form-group.has-danger {
    label:before {
        border-color: $danger;
    }
}
.required-label{
    color: $danger;
}

select.form-control-danger ~ .nice-select{
    border-color: $danger;
}

//
// [data-filter]
// 
.data-filter-group div[data-filter] {
    display: none;
}

.data-filter-group div[data-filter].active {
    display: block;
}


// 
// section-form
// 


@include media-breakpoint-down(md) {}


@include media-breakpoint-down(sm) { }