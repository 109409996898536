//
// FIX olw mobile scroll
//
.owl-carousel .owl-stage,
.owl-carousel.owl-drag .owl-item {
    -ms-touch-action: auto;
    touch-action: auto;
}

.owl-dots{
    display: none;
}
.owl-button-left, .owl-button-right{
    width: 41px;
    height: 49px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
    font-size: 8px;
    &:hover{
        background-color: $primary;
        color: $white;
    }
}

.owl-button-left{
    left: 0;
}
.owl-button-right{
    right: 0;
}

.gallery-container{
    position: relative;
}





@include media-breakpoint-down(lg) {}

@include media-breakpoint-down(md) {}

@include media-breakpoint-down(sm) {}

