// 
// page: home
// 


// 
// hero home
// 
.hero-home{
    position: relative;
    padding-right: 140px;
    .swiper-container{
        background-color: $primary;
    }

    .swiper-hero-right{
        position: absolute;
        z-index: 55;
        right: 46px;
        bottom: 110px;
        text-align: center;
    }

    .swiper-pagination{
        position: static;
        display: inline-block;
        width: auto;

        .swiper-pagination-bullet{
            display: block;
            width: 28px;
            height: 8px;
            background-color: $dark;
            border-radius: 0px;
            margin-bottom: 20px;
            margin-left: 0;
            margin-right: 0;
            outline: none;
            border:0px;
            opacity: 1;
        }
        .swiper-pagination-bullet-active{
            background-color: $primary;
        }

        .swiper-pagination-bullet:last-of-type{
            margin-bottom: 0;
        }
    }
    .scroll-hero{
        padding-top: 20px;
        text-align: center;
        font-size: 48px;
        [class^="icon"]{
            display: inline-block;
            position: relative;
            animation: anim-bounce-sm 0.85s ease-in-out infinite alternate;
        }
    }
}

@keyframes anim-bounce-sm {
    from {
        top: 0px;
    }
    to {
        top: 10px;
    }
}

.slide-hero {
    .slide-hero-image {
        position: relative;
    }
    .image-holder {
        padding: 0;
        height: 78vh;
        opacity: 0.3;
    }
    .slide-hero-content {
        position: absolute;
        z-index: 44;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: flex-end;
        padding-top: 130px;
        padding-bottom: 280px;
        padding-left: 14%;

        h2{
            font-size: 60px;
            color: $white;
            font-weight: 700;
            text-transform: uppercase;
        }
        h3{
            font-size: 42px;
            color: $white;
            font-weight: 300;
        }
    }
}

#swiper-hero {
    position: relative;
    .swiper-slide{
        h2, h3{
            opacity: 0;
            transform: translateY(40px);
            transition: all 0.45s;
        }
    }

    .swiper-slide-active{
       h2, h3{
           opacity: 1;
           transform: translateY(0px);
       }
    }
}


// 
// home portfolio
// 
.home-portfolio{
    margin-top: -270px;
    position: relative;
    z-index: 44;
    .portfolio-all{
        text-align: right;
        padding: 20px 0;
        a{
            color: $white;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 12px;
        }
    }
}

// 
// home versus
// 
.versus-callout{
    padding: 120px 0px 350px 0px;  
    padding-left: 45%;
    position: relative;
    margin-bottom: 100px;
    h2{
        font-weight: 300;
        color: #a3acac;
        position: relative;
        z-index: 2;
        span{
            color: $primary;
            font-weight: 700;
        }
    }
    img{
        position: absolute;
        z-index: 1;
        top: 180px;
        right: 54%;
        max-width: 50%;
    }
}

// 
// home news
// 

.home-news{
    position: relative;
    z-index: 11;
    
}

.home-news-title{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 80px;
    h2{
        margin: 0;
        text-transform: uppercase;
        background-color: $white;
        padding: 0 20px;
    }
    .news-more{
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        color: $dark;
        &:hover{
            color: $primary;
        }
    }
}

.home-news{

    .list-post{
        position: relative;
        &:before{
            content: "";
            position: absolute;
            left: -50px;
            right: -50px;
            top: -120px;
            bottom: -50px;
            border: 1px solid $gray-200;
            z-index: -1;
        }
    }
    ul.list-post > li:before, ul.list-post > li:after{
        content: none;
    }

    ul.list-post > li{
        border:0px;
        border-right: 1px solid $gray-200;
    }
    ul.list-post > li:last-child{
        border:0px;
    }
}


// 
// home history
// 
.home-history{
    overflow: hidden;
}

.home-history-container{
    position: relative;
    &:before{
        content: "";
        position: absolute;
        left: -100vw;
        right: -100px;
        top: -120px;
        bottom: 150px;
        background-color: $primary;
        z-index: -1;
    }
}

.home-history-row{
    padding: 90px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 50px;
    .hh-content{
        flex: 0 0 28%;
        padding: 55px 0 160px 0px;
        h2, h5, p{
            color: $white;
        }
        h2{
            text-transform: uppercase;
            margin-bottom: 40px;
        }
        h5{
            font-weight: 400;
            margin-bottom: 40px;
        }
        p{
            margin-bottom: 45px;
        }
        
    }
    .hh-image{
        position: relative;
        flex: 0 0 40%;
        z-index: 2;
    }
    .hh-year{
        position: absolute;
        z-index: 20;
        top: 40%;
        left: -70%;
        color: $white;
        opacity: 0.4;
        font-size: 180px;
    }
}


@include media-breakpoint-down(xl) {
    // home hero
    .hero-home{
        padding-right: 80px;
        .swiper-hero-right{
            right: 14px;
        }
    }

    .slide-hero {
        .slide-hero-content{
            padding-bottom: 240px;
            h2{
                font-size: 38px;
            }
            h3{
                font-size: 26px;
            }
        }
        .image-holder{
            height: 90vh;
        }
    }

    // home portfolio
    .home-portfolio{
        .portfolio-all{
            padding-right: 20px;
        }
    }

    // home versus
    .versus-callout{
        margin-bottom: 0;
    }

    // home news
    .home-news{
        .list-post{
            position: relative;
            &:before{
                content: "";
                position: absolute;
                left: 0px;
                right: 0px;
                top: -120px;
                bottom: -50px;
                border: 1px solid $gray-200;
                z-index: -1;
            }
        }
    }

    // home history
    .home-history-row {
        .hh-year{
            font-size: 120px;
            left: -60%;
        }
    }
}

@include media-breakpoint-down(lg) {
    // home hero
    .hero-home{
        padding-right: 0px;
        .swiper-hero-right{
            display: none;
        }
    }

    .slide-hero .slide-hero-content{
        align-items: center;
        padding-bottom: 60px;
    }

    // home portfolio
    .home-portfolio {
        margin-top: -110px;
    }
    
    //home versus
    .versus-callout {
        padding: 120px 0px 200px 0px;
        padding-left: 45%;
        img {
            max-width: 40%;
        }
    }
}


@include media-breakpoint-down(md) {

    // home hero
    .slide-hero .slide-hero-content{
        padding-left: 5%;
        padding-right: 10%;
        padding-top: 50px;
    }
    // home portfolio
    .home-portfolio{
        .list-portfolio li:last-child{
            display: none;
        }
    }
    .home-news .list-post:before{
        content: none;
    }

    // home news
    .home-news-title{
        margin-bottom: 20px;
    }

    .home-news ul.list-post > li{
        border:0px;
    }

    // versus callout
    .versus-callout {
        padding: 50px 0px 120px 0px;
        padding-left: 45%;
        img{
            top: 90px;
            right: auto;
            left: 0;
        }
    }

    // home history
    .home-history{
        background-color: $primary;
    }
    .home-history-row{
        padding: 30px 0;
        margin-bottom: 0;
        align-items: center;
        .hh-content{
            padding: 30px 0;
        }
        .hh-year{
            font-size: 110px;
        }
    }
}


@include media-breakpoint-down(sm) {

    // home hero
    .slide-hero .slide-hero-content h2{
        font-size: 26px;
    }

    // home portfolio
    .home-portfolio{
        margin-top: 20px;
        .portfolio-all{
            padding-right: 0;
           a{
                color: $dark;
                padding-right: 0;
            }
       }
    }

    // home versus
    .versus-callout{
        padding: 20px 0;
        padding-top: 0;
        img{
            position: static;
            max-width: 100%;
        }
        h2{
            font-size: 26px;
        }
    }

    // home news
    .home-news-title h2{
        padding-left: 0;
    }

    // home history
    .home-history-row{
        display: block;
        .hh-content{
            padding: 30px 0;
            h2{
                margin-bottom: 15px;
            }
            h5{
                margin-bottom: 15px;
            }
        }
        .hh-year{
            font-size: 70px;
            left: 20px;
        }
    }
}
