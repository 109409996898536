.page{
    &-title{
        display: flex;
        justify-content: space-between;
        margin-bottom: 65px;
        h1{
            text-transform: uppercase;
            margin: 0;
        }
    }
    &-hero{
        
    }
    &-bredcrumbs{
        
    }

    &-content{
       
    }
}

.page-hero ~ .page-bredcrumbs{
    margin-top: -80px;
}

@include media-breakpoint-down(xl) {}


@include media-breakpoint-down(lg) {}


@include media-breakpoint-down(md) {
    .page-hero ~ .page-bredcrumbs{
        margin-top: 20px;
    }
    .page-title{
        margin-bottom: 30px;
    }
}

@include media-breakpoint-down(sm) {
    .page-title{
        margin-bottom: 15px;
    }
}