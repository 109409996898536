// 
// Cards
// 
ul.list-post{
    padding-top: 10px;
    > li {
        border-right: 1px solid $gray-200;
        border-top: 1px solid $gray-200;
        position: relative;
        &:before, &:after{
            content: "";
            position: absolute;
            left: -1px;
            right: -1px;
            height: 15px;
            background-color: $white;
        }
        &:before{
            top: 0px;
        }
        &:after{
            bottom: 0px;
        }
    }

    > li:nth-child(3n){
        border-right: 0px;
    }

    > li:nth-child(1), > li:nth-child(2), > li:nth-child(3) {
        border-top: 0px;
        &:before{
            content: none;
        }
    }
}
.card {
    border-radius: 0px;
    background-color: transparent;
    border: 0px;
    transition: all 0.35s;
    position: relative;
    margin-bottom: 30px;
    transition: all 0.3s;
    .card-body {
        padding: 20px;
    }
    .card-title{
        transition: all 0.3s;
        margin-bottom: 25px;
        display: block;
        color: $dark;
        &:hover{
            color: $primary;
        }
    }
    .card-meta {
        font-size: 13px;
        font-weight: 300;
        margin-bottom: 20px;
    }
}


@include media-breakpoint-down(xl) {
    .card{
        .card-body{
            padding: 15px;
        }
    }
}

@include media-breakpoint-down(lg) {
    .list-post .card, .list-portfolio .card{
        .h5{
            font-size: 16px;
        }
    }
}






@include media-breakpoint-down(md) {
    .card{
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    }

    ul.list-post > li:before, ul.list-post > li:after{
        content: none;
    }
    ul.list-post > li{
        border:0px;
    }
    ul.list-post{
        padding-top: 0;
    }
}


@include media-breakpoint-down(sm) {
    .card .card-title{
        margin-bottom: 15px;
    }
}