// 
// Footer
// 

.footer{
    border-top: 1px solid $gray-200;
}

.footer-row{
    display: flex;
    align-items: center;
    .footer-brand{
        flex: 0 0 auto;
        padding-right: 80px;
        img{
            max-width: 145px;
        }
    }

    .footer-copyright{
        padding-top: 25px;
        display: flex;
        padding-right: 60px;
        p{
            
            margin: 0;
        }
    }

    .footer-data{
        padding: 70px 0;
        padding-left: 80px;
        border-left: 1px solid $gray-200;
        flex: 1 1 auto;
        display: flex;
        justify-content: space-between;
        .footer-data-col{
            padding: 20px;
            p{
                margin: 0;
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    .footer-row {
        .footer-brand{
            padding-right: 30px;
        }
        .footer-data{
            padding-left: 30px;
        }
        .footer-copyright{
            padding-right: 30px;
        }
        .footer-copyright{
            display: block;
        }
    }
}

@include media-breakpoint-down(lg) {
    .footer-row {
        .footer-data{
            flex: 1 0 auto;
        }
    }
}

@include media-breakpoint-down(md) {
    .footer{
        padding-top: 40px;
    }
    .footer-row {
        display: block;
        text-align: center;
        .footer-brand{
            padding-right: 0;
        }
        .footer-data{
            display: block;
            border:0px;
            .footer-data-col{
                padding: 10px 0px;
            }
        }
        .footer-copyright, .footer-data{
            padding: 20px 0;
        }
    }
}