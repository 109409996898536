// 
// page: reference
// 
.reference-item{
    .reference-image{
        border:1px solid $gray-200;
    }
    .reference-title{
        padding-top: 10px;
        text-align: center;
        p{
            font-size: 14px;
            color: $text-color;
            transition: all 0.3s;
        }
    }
    .reference-icon{
        position: absolute;
        right: 10px;
        top: 10px;
        width: 60px;
        height: 60px;
        background-color: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $white;
        font-size: 24px;
        opacity: 0;
        visibility: hidden;
        transform: scale(0.3);
        transition: all 0.3s;
    }
}

.reference-item:hover{
    .reference-title{
        p{
            color: $primary;
        }
    }
    .reference-icon{
        opacity: 1;
        visibility: visible;
        transform: scale(1);
    }
}