// 
// Offcanvas
// 
.offcanvas {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 53px;
    bottom: 0px;
    background-color: $primary;
    z-index: 999;
    transform: translate3d(-100%, 0, 0);
    transition: all .4s;
    padding: 40px 0px;
    overflow-y: auto;

    ul.offcanvas-menu{
        > li > a{
            color: $white;
            display: block;
            padding: 10px 20px;
            font-weight: 700;
            text-transform: uppercase;
            border-bottom: 1px solid rgba( $white, 0.2 );
        }
    }

    ul.offcanvas-lang{
        display: flex;
        padding-left: 16px;
        li a{
            color: $white;
            font-size: 14px;
            font-weight: 700;
            display: block;
            padding: 2px 4px;
            text-transform: uppercase;
        }
    }
}

body.menu-is-active{
    overflow-y: hidden;
    #offcanvas {
        transform: translate3d(0, 0, 0);
    }
}