// colors
$dark:             #000;
$white:            #fff;
$text-color:       #2b2d36;
$primary:          #198e90;


$gray-100:         #f7f7f7;
$gray-200:         #dadada;
$gray-300:         #cecece;
$gray-400:         #e5e5e5;










