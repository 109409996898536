html {
    box-sizing: border-box;
}

/**
   * Make all elements from the DOM inherit from the parent box-sizing
   * Since `*` has a specificity of 0, it does not override the `html` value
   * making all elements inheriting from the root box-sizing value
   * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
   */

*,
*::before,
*::after {
    box-sizing: inherit;
}

img {
    max-width: 100%;
}

// 
// Row sizes
// 
.row-lg {
    margin-left: -25px;
    margin-right: -25px;
}
.row-lg > .col,
.row-lg > [class*=col-] {
    padding-right: 25px;
    padding-left: 25px;
}

.row-md {
    margin-left: -10px;
    margin-right: -10px;
}
.row-md > .col,
.row-md > [class*=col-] {
    padding-right: 10px;
    padding-left: 10px;
}

.form-row {
    margin-left: -9px;
    margin-right: -9px;
}
.form-row > .col,
.form-row > [class*=col-] {
    padding-right: 9px;
    padding-left: 9px;
}





