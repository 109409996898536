// 
// Aos
// 
[data-aos="offer-animation"] {
    .offer-item{
        opacity: 0;
        transform: translateY(50px);
        transition: all 0.4s 0.35s;
    }
    &.aos-animate{
        .offer-item{
            opacity: 1;
            transform: translateY(0px);
        }
    }
}

[data-aos="history-text"] {
    opacity: 0;
    transform: translateY(50px);
    transition: all 0.4s 0.4s;
    &.aos-animate{
        opacity: 1;
        transform: translateY(0px);
    }
}

[data-aos="timeline"] {
    @media screen and (min-width: 992px) {
        &:after{
            height: 0;
            transition: all 1s 0.25s;
        }
        
        &.aos-animate {
            &:after{
                height: 100%;
            }
        }
    }
}


[data-aos="timeline-item-left"] {
    @media screen and (min-width: 992px) {
        opacity: 0;
        transform: translateX(70px);
        transition: all 0.5s 0.35s;
        
        &.aos-animate {
            opacity: 1;
            transform: translateX(0px);
        }
    }
}

[data-aos="timeline-item-right"] {
    @media screen and (min-width: 992px) {
        opacity: 0;
        transform: translateX(-70px);
        transition: all 0.5s 0.35s;
        
        &.aos-animate {
            opacity: 1;
            transform: translateX(0px);
        }
    }
}