// 
// page hero
// 

.hero-spacer{
    height: 135px;
}

.page-hero{
    position: relative;
    padding-right: 140px;
    &-image{
        position: relative;
        overflow: hidden;
        padding-bottom: 44.5%;
        background-color: $primary;
    }
    &-bg{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        opacity: 0.3;
    }
}

.page-hero-border{
    position: relative;
    z-index: 2;
    height: 55px;
    background-color: $primary;
    width: calc( 100% - 140px );
    &:after{
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 55px;
        height: 670px;
        background-color: $primary;
    }
}


@include media-breakpoint-down(xl) {
    .page-hero{
        padding-right: 80px;
    }

    .page-hero-border{
        width: calc( 100% - 80px );
        &:after{
            height: 480px;
        }
    }
}


@include media-breakpoint-down(lg) {
    .page-hero{
        padding-right: 0px;
    }
    .page-hero-border{
        display: none;
    }

    .hero-spacer{
        height: 80px;
    }
}


@include media-breakpoint-down(md) {
    .hero-spacer{
        height: 90px;
    }
}

@include media-breakpoint-down(sm) {}