// 
// header
// 
.header {
    position: fixed;
    z-index: 99;
    left: 0;
    right: 140px;
    top: 0;
    transition: all 0.3s;
    background-color: $white;
    .header-container{
        position: relative;
        border-bottom: 1px solid $gray-200;
        border-right: 1px solid $gray-200;
    }
    .header-row {
        display: flex;
        align-items: flex-start;
    }
    .header-brand {
        padding-left: 70px;
        flex: 0 0 200px;
        padding-top: 18px;
        transition: all 0.3s;
        img{
            max-width: 175px;
            transition: all 0.3s;
        }
    }
    ul.header-menu {
        flex: 1 1 auto;
        display: flex;
        margin: 0;
        justify-content: flex-end;
        > li > a {
            color: $dark;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 700;
            padding: 55px 20px;
            display: block;
            position: relative;
            transition: all 0.3s;
            &:after{
                content: "";
                position: absolute;
                left: 50%;
                top: 0;
                height: 8px;
                width: 24px;
                margin-left: -12px;
                background-color: $primary;
                opacity: 0;
            }
        }
        > li:hover > a{
            color: $primary;
        }

        li a.active{
            color: $primary;
            &:after{
                opacity: 1;
            }
        }
    }

    ul.header-lang{
        position: absolute;
        right: -85px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 1;
        transition: all 0.3s;
        li a{
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 700;
            padding: 0 5px;
            color: $dark;
            line-height: 1;
            &:hover{
                color: $primary;
            }
        }
    }
    #toggle-menu{
        display: none;
    }
}

.header-sticky{
    ul.header-menu {
        > li > a {
            padding: 20px;
        }
    }

    .header-brand {
        flex: 0 0 200px;
        padding-top: 5px;
        img{
            max-width: 90px;
        }
    }

    ul.header-lang{
        opacity: 0;
    }
}




@include media-breakpoint-down(xl) {
    .header{
        right: 80px;
        ul.header-lang{
            right: -60px;
        }.header-brand{
            padding-left: 40px;
        }
    }
}

@include media-breakpoint-down(lg) {

    .header, .header-sticky{
        ul.header-menu {
            > li > a {
                padding: 20px;
            }
        }
    
        .header-brand {
            flex: 0 0 200px;
            padding-top: 5px;
            img{
                max-width: 80px;
            }
        }
    
        ul.header-lang{
            opacity: 0;
        }
    }
    .header{
        right: 0;
        .header-row{
            justify-content: space-between;
            align-items: center;
        }
        .header-container{
            border-right: 0px;
        }
        .header-brand{
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 0;
        }
        ul.header-menu, .ul.header-lang{
            display: none;
        }
        #toggle-menu{
            display: block;
        }
    }
}