// 
// page: portfolio
// 
.portfolio-content{
    .row{
        margin-bottom: 100px;
    }

    h5{
        font-weight: 400;
        margin-bottom: 40px;
    }
    
}

.portfolio-link{
    display: block;
    background-color: $gray-100;
    text-align: center;
    padding: 20px 10px;
    font-size: 14px;
    font-weight: 700;
    color: $primary;
    margin: 20px -15px;
    margin-top: 60px;
    transition: all 0.3s;
    &:hover{
        background-color: $primary;
        color: $white;
    }
}

@include media-breakpoint-down(md) {
    .portfolio-content{
        .row{
            margin-bottom: 40px;
        }
    
        h5{
            margin-bottom: 20px;
        }
        .image-holder{
            margin-bottom: 20px;
        }
        .portfolio-link{
            margin: 20px 0;
        }
    }
}

@include media-breakpoint-down(sm) {
    .portfolio-content{
        .row{
            margin-bottom: 20px;
        }
    }
}
