// 
// page: contact
// 

.contact-data-row{
    margin-bottom: 45px;
    .contact-form, .data-contact{
        h5{
            padding-top: 20px;
            margin-bottom: 45px;
            font-weight: 400;
        }
    }
    .contact-form{
        h5{
            padding-left: 20px;
        }
    }
}


.contact-data-col{
    border-right: 1px solid $gray-200;
    position: relative;
    &:after{
        content: "";
        position: absolute;
        top: 0;
        right: -6px;
        width: 6px;
        height: 50px;
        background-color: $primary;
    }
}

.contact-data-group{
    margin-bottom: 25px;
    p{
        margin: 0;
    }
}

.g-map, .g-map-static{
    padding-right: 140px;
}





@include media-breakpoint-down(xl) {
    .g-map, .g-map-static{
        padding-right: 80px;
    }
}


@include media-breakpoint-down(lg) {
    .g-map, .g-map-static{
        padding-right: 0px;
    }
}


@include media-breakpoint-down(md) {
    .contact-data-row .contact-form h5{
        padding-left: 0;
    }
    .contact-data-col{
        border:0px;
        &:after{
            content: none;
        }
    }
}

@include media-breakpoint-down(sm) {
    .contact-data-row .data-contact h5, .contact-data-row .contact-form h5{
        margin-bottom: 10px;
    }
}


