// 
// Article
// 

article{
    padding: 40px 18%;
    .article-title{
        color: $primary;
    }
    .article-meta{
        font-weight: 300;
        margin-bottom: 20px;
        font-size: 13px;
    }
    h2, h3, h4, h5, h6, p{
        margin-bottom: 30px;
    }
}

@include media-breakpoint-down(md) {
    article {
        padding: 20px 0px;
    }
}