body {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Merriweather Sans', sans-serif;
    color: $text-color;
    font-size: 14px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-family: 'Merriweather Sans', sans-serif;
    font-weight: 700;
    color: $dark;
}

h1, .h1 {
    font-size: 72px;
}

h2, .h2 {
    font-size: 46px;
}

h3, .h3 {
    font-size: 26px;
}

h4, .h4 {
    font-size: 26px;
}

h5, .h5 {
    font-size: 22px;
}

h6, .h6 {
    font-size: 20px;
}


a,
a:hover,
a:focus,
button,
button:focus,
button:hover {
    text-decoration: none;
    outline: none;
}

a {
    color: $primary;
    transition: all 0.35s;
}

a:hover,
a:focus {
    color: darken( $primary, 10% );
    
}

@include media-breakpoint-down(xl) {
    h1, .h1{
        font-size: 64px;
    }
    h2, .h2{
        font-size: 44px;
    }

    h5, .h5{
        font-size: 20px;
    }
}

@include media-breakpoint-down(lg) {}

@include media-breakpoint-down(md) {
    h1, .h1{
        font-size: 44px;
    }
}

@include media-breakpoint-down(sm) {
    h1, .h1{
        font-size: 26px;
    }
    h5, .h5{
        font-size: 18px;
    }
}